import { HeaderHome } from 'components/HeaderHome';
import RedirectLoader from 'components/RedirectingLoader';
import SearchFilterIcons from 'components/SearchFilterIcons';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import BannerHome from './BannerHome';
import GiftCard from './GiftCard';
import MentorListCarousel from './MentorListCarousel';
import Newsletter from './Newsletter';
import useHome from './useHome';
import AboutUs from './AboutUs';

export default function Home() {
  const { t } = useTranslation();
  const { data, handleRedirectWithFilters } = useHome();

  return (
    <Suspense fallback={<RedirectLoader />}>
      <HeaderHome />
      <SearchFilterIcons handleFilterClick={handleRedirectWithFilters} />
      <GiftCard />
      <MentorListCarousel
        title={t('traveler.home.meetMentors')}
        mentors={data}
      />

      <AboutUs />
      <BannerHome />
      <Newsletter />
    </Suspense>
  );
}
