import { EnvironmentOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import { ContentaTab } from 'components/Styled/ContentaTab';
import { MEETING_TYPES } from 'constants/types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AvailableSchedulesBook from './AvailableSchedulesBook';

import * as S from './styles';

function ScheduleDates({
  advisor,
  auxClass,
  availableSchedules,
  availableTimesWithDays,
  handleClickSchedule,
  handleSubmitSchedule,
  isLoading,
  loadingDates,
  nextThreeDays,
  previousThreeDays,
  schedule = null,
}) {
  const { t } = useTranslation();
  const [availablePresentialTimes, setAvailablePresentialTimes] = useState({});
  const currentPage = window.location.pathname.split('/')[1];

  const tabCommonProps = {
    auxClass,
    availableSchedules,
    availableTimesWithDays,
    handleClickSchedule,
    handleSubmitSchedule,
    isLoading,
    loadingDates,
    nextThreeDays,
    previousThreeDays,
  };

  const getAvailablePresentialSchedules = () => {
    let availablePresentialSlots = {
      valueEightHours: advisor?.valueEightHours,
      valueFourHours: advisor?.valueFourHours,
      valueSixHours: advisor?.valueSixHours,
      valueTenHours: advisor?.valueTenHours,
    };

    availablePresentialSlots = Object.fromEntries(
      Object.entries(availablePresentialSlots).filter(
        ([_, v]) => v !== undefined
      )
    );

    setAvailablePresentialTimes(availablePresentialSlots);
  };

  useEffect(() => {
    getAvailablePresentialSchedules();
  }, [advisor]);

  return (
    <S.ScheduleDateContainer className="schedule-modal">
      <h1 className="schedule-modal__title">
        {t('findAdvisor.scheduleModal.title')}
      </h1>
      <ContentaTab className={`tab-full ${currentPage}`}>
        {schedule?.schedulingType === MEETING_TYPES.online ||
        !schedule?.schedulingType ? (
          <Tabs.TabPane
            tab={
              <div className="schedule-modal__tab">
                <VideoCameraOutlined />
                <span>{t('mentorProfile.schedule.videoCall')}</span>
              </div>
            }
            key="item-1"
          >
            <AvailableSchedulesBook
              {...tabCommonProps}
              typeSchedule={MEETING_TYPES.online}
            />
          </Tabs.TabPane>
        ) : null}

        {schedule?.schedulingType === MEETING_TYPES.presential ||
        !schedule?.schedulingType ? (
          <Tabs.TabPane
            tab={
              <div className="schedule-modal__tab">
                <EnvironmentOutlined />
                <span>{t('mentorProfile.schedule.presential')}</span>
              </div>
            }
            key="item-2"
          >
            <AvailableSchedulesBook
              {...tabCommonProps}
              isInPerson
              slotsPresential={availablePresentialTimes}
              schedule={schedule}
              typeSchedule={MEETING_TYPES.presential}
            />
          </Tabs.TabPane>
        ) : null}
      </ContentaTab>
    </S.ScheduleDateContainer>
  );
}

export default ScheduleDates;
