import { Result } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorContainer } from './styles';

const ServerErrorPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const checkThirdPartyCookies = () => {
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      document.body.appendChild(iframe);

      const cookieName = 'testThirdPartyCookie';
      iframe.contentWindow.document.cookie = `${cookieName}=1; SameSite=None; Secure`;

      document.body.removeChild(iframe);
    };

    checkThirdPartyCookies();
  }, []);

  const title = t('common.error.cookiesDisabled');
  const instructions = (
    <div className="error-instructions">
      <h3>{t('common.error.enableCookies.title')}</h3>

      <div className="column">
        <h4>{t('common.error.enableCookies.chrome.step1')}</h4>
        <p>{t('common.error.enableCookies.chrome.step2')}</p>
        <p>{t('common.error.enableCookies.chrome.step3')}</p>
        <p>{t('common.error.enableCookies.chrome.step4')}</p>
        <p>{t('common.error.enableCookies.chrome.step5')}</p>
        <p>{t('common.error.enableCookies.chrome.step6')}</p>
      </div>
      <div className="column">
        <h4>{t('common.error.enableCookies.firefox.step1')}</h4>
        <p>{t('common.error.enableCookies.firefox.step2')}</p>
        <p>{t('common.error.enableCookies.firefox.step3')}</p>
        <p>{t('common.error.enableCookies.firefox.step4')}</p>
        <p>{t('common.error.enableCookies.firefox.step5')}</p>
      </div>
      <div className="column">
        <h4>{t('common.error.enableCookies.edge.step1')}</h4>
        <p>{t('common.error.enableCookies.edge.step2')}</p>
        <p>{t('common.error.enableCookies.edge.step3')}</p>
        <p>{t('common.error.enableCookies.edge.step4')}</p>
        <p>{t('common.error.enableCookies.edge.step5')}</p>
        <p>{t('common.error.enableCookies.edge.step6')}</p>
      </div>

      <div className="column">
        <h4>{t('common.error.enableCookies.safari.step1')}</h4>
        <p>{t('common.error.enableCookies.safari.step2')}</p>
        <p>{t('common.error.enableCookies.safari.step3')}</p>
        <p>{t('common.error.enableCookies.safari.step4')}</p>
        <p>{t('common.error.enableCookies.safari.step5')}</p>
        <p>{t('common.error.enableCookies.finalNote')}</p>
      </div>
    </div>
  );

  return (
    <ErrorContainer>
      <Result status="warning" title={title} extra={<>{instructions}</>} />
    </ErrorContainer>
  );
};

export default ServerErrorPage;
