/* eslint-disable react/no-danger */
import { ContentaButtonPrimary } from 'components/Styled/ContentaButton';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import * as S from './styles';
import { useLanguage } from 'contexts/languageContext';

function BannerHome() {
  const { t } = useTranslation();
  const { currentLanguage } = useLanguage();

  const handleRedirectToRegister = () => {
    if (currentLanguage === 'pt-BR') {
      window.open('https://www.contenta360.com/traveladvisors-pt/', '_blank');
      return;
    }
    window.open('https://www.contenta360.com/traveladvisors', '_blank');
  };

  return (
    <S.Container>
      <div className="banner-home banner-forum">
        <div className="banner-home__content">
          <h2
            className="banner-home__title"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(t('home.banner.title')),
            }}
          ></h2>
          <ContentaButtonPrimary onClick={handleRedirectToRegister}>
            {t('home.banner.buttonKnow')}
          </ContentaButtonPrimary>
        </div>
      </div>
    </S.Container>
  );
}

export default BannerHome;
