import styled from 'styled-components';
import convertPxToRem from 'utils/convert-px-rem';

export const AvailableScheduleContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 90px);
  grid-gap: 4px;
  overflow-y: scroll;
  max-height: 400px;
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    grid-gap: 16px;
  }

  .available-schedules {
    align-items: center;
    display: flex;
    flex-flow: column wrap;
    gap: 8px;
    justify-content: flex-start;

    .available-schedules__time {
      background: ${({ theme }) => theme.colors.neutralWhite[10]};
      border-radius: 8px;
      border: 2px solid ${({ theme }) => theme.colors.neutralWhite[40]};
      cursor: pointer;
      min-width: 90px;
      width: 100%;
      max-width: 115px;
      padding: 8px;
      text-align: center;
      transition: all 0.3s ease-in-out;
      &:hover {
        background: rgba(69, 10, 171, 0.16);
        border: 2px solid rgba(69, 10, 171, 0.32);
      }

      &.--disabled {
        background: ${({ theme }) => theme.colors.neutralWhite[10]};
        border: 2px solid ${({ theme }) => theme.colors.neutralWhite[40]};

        span {
          color: ${({ theme }) => theme.colors.neutralWhite[40]};
        }
      }

      &.selected {
        background: ${({ theme }) => theme.colors.blue[40]}!important;
        border: 2px solid ${({ theme }) => theme.colors.blue[40]}!important;
        span {
          color: ${({ theme }) => theme.colors.neutralWhite[10]}!important;
        }
      }

      span {
        color: ${({ theme }) => theme.colors.purple.dark};
        font-size: ${convertPxToRem(14)};
        font-family: ${({ theme }) => theme.fonts.primary};
        font-weight: 700;
      }
    }

    .available-schedules__time--booked {
      border-radius: 8px;
      border: 2px solid transparent;
      min-width: 90px;
      padding: 8px;

      span {
        color: transparent;
      }
    }
  }
`;
