import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, Outlet } from 'react-router-dom';
import { ContainerApp } from 'components/ContainerApp';
import RedirectLoader from 'components/RedirectingLoader';
import checkIsRegistered from 'utils/checkIsRegistered';
import { useUser } from 'contexts/userContext';
import { useState, useEffect } from 'react';

export function ProtectedRoute({ children }) {
  const { isAuthenticated, isLoading } = useAuth0();
  const { whoAmI, whoAmILoading } = useUser();
  const [isRegisteredChecked, setIsRegisteredChecked] = useState(false);

  useEffect(() => {
    if (!whoAmILoading && Object.keys(whoAmI).length > 0) {
      setIsRegisteredChecked(true);
    }
  }, [whoAmILoading, whoAmI]);

  if (isLoading || whoAmILoading) {
    return <RedirectLoader />;
  }

  if (!isRegisteredChecked) {
    return <Navigate to="/" replace />;
  }

  const isRegisterRoute = window.location.pathname.includes('register');
  const isLoaded = !isLoading && !isAuthenticated;

  if (isLoaded && !isRegisterRoute && !isRegisteredChecked) {
    return <Navigate to="/" replace />;
  }

  const isRegistered = checkIsRegistered({ whoAmI });
  if (!isRegistered && !isRegisterRoute) {
    return <Navigate to="register" replace />;
  }

  return <ContainerApp isMentor>{children || <Outlet />}</ContainerApp>;
}
