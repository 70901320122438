import Avatar from 'components/Avatar';
import { useTranslation } from 'react-i18next';
import * as S from './styles';
import RatingSingleStar from 'components/RatingSingleStar';
import { Skeleton } from 'antd';

function AdvisorCard({ advisor, isLoadingAdvisor = false }) {
  const { name, title, image, profilePhrase, video } = advisor;
  const { t } = useTranslation();

  if (isLoadingAdvisor) {
    return <Skeleton avatar paragraph={{ rows: 2 }} />;
  }

  return (
    <S.CardContainer className="advisor-card">
      <Avatar src={image} alt="Advisor" />
      <div className="advisor-card__content">
        <h3 className="advisor-card__name">{name}</h3>
        {/* <h3 className="advisor-card__name">
          {name || <Skeleton.Button size="small" block={false} />}
        </h3> */}
        <span className="advisor-card__phrase">
          {profilePhrase || t('mentorProfile.noPhrase')}
        </span>
        <p className="advisor-card__title">{title}</p>
        {/* <LicensedProfileTag t={t} /> */}
        <RatingSingleStar rating={advisor.rating} />
      </div>
      {video && (
        <div className="advisor-card__video">
          <video controls>
            <source src={video} type="video/mp4" />
          </video>
        </div>
      )}
    </S.CardContainer>
  );
}

export default AdvisorCard;
