import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import MentorCard from 'pages/TravellerPages/Home/MentorCard';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import * as S from './styles';

function MentorListCarousel({ title, mentors }) {
  if (!mentors || mentors.length === 0) {
    return null;
  }

  return (
    <S.NotTalkedContainer>
      <div className="not-talked__mentors">
        <h3>{title}</h3>
      </div>

      <Swiper
        spaceBetween={16}
        slidesPerView={'auto'}
        className="not-talked__mentors-card"
        // modules={[Pagination, Navigation]}
        // navigation={true}
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination]}
      >
        {mentors?.map((mentor) => (
          <SwiperSlide>
            <MentorCard mentor={mentor} />
          </SwiperSlide>
        ))}
      </Swiper>
    </S.NotTalkedContainer>
  );
}

export default MentorListCarousel;
