import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Form } from 'antd';
import { FlagMenu } from 'components/FlagMenu';
import { ContentaButtonPrimary } from 'components/Styled/ContentaButton';
import { ContentaCard } from 'components/Styled/ContentaCard';
import { ContentaInput } from 'components/Styled/ContentaInput';
import { ContentaInputGroup } from 'components/Styled/ContentaInputGroup';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { authService } from 'services/Auth0Service';
import LoginByGoogle from '../LoginGoogle';
import { FooterLogin, LoginBoxHeader } from '../styled';

const REDIRECT_URI = `${window.location.origin}/redirecting`;

const LoginForm = ({ isAdvisor = false }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const infoByRole = isAdvisor
    ? {
        title: t('login.loginAsAdvisor'),
        link: '/guide/register',
      }
    : {
        title: t('login.loginAsTraveller'),
        link: '/register',
      };

  const onSubmit = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      const { username, password } = values;

      if (!username || !password) {
        setLoading(false);
        return;
      }

      authService.login(
        {
          realm: 'Username-Password-Authentication',
          connection: 'Username-Password-Authentication',
          username,
          password,
          redirectUri: REDIRECT_URI,
          responseType: 'token id_token',
        },
        (err) => {
          setLoading(false);
          if (err) {
            console.error('Login error:', err);
            return;
          }
        }
      );
    } catch (error) {
      setLoading(false);
    }
  };

  const handleClosePopup = () => {
    console.log('close popup');
  };

  return (
    <ContentaCard className="login-form">
      <LoginBoxHeader>
        <h1
          dangerouslySetInnerHTML={{
            __html: t(
              isAdvisor ? 'login.loginAsAdvisor' : 'login.loginAsTraveler'
            ),
          }}
        />
        <FlagMenu isLogin />
      </LoginBoxHeader>

      <Form form={form} layout="vertical">
        <ContentaInputGroup>
          <Form.Item
            label={t('login.user')}
            name="username"
            className="initial-font-transform"
            rules={[{ required: true, message: t('login.usernameRequired') }]}
          >
            <ContentaInput
              placeholder="email@email.com"
              onPressEnter={onSubmit}
            />
          </Form.Item>
        </ContentaInputGroup>

        <ContentaInputGroup>
          <Form.Item
            label={t('login.password')}
            name="password"
            className="initial-font-transform"
            rules={[{ required: true, message: t('login.passwordRequired') }]}
          >
            <ContentaInput.Password
              placeholder="********"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              onClick={() => setPasswordVisible((prevState) => !prevState)}
              onPressEnter={onSubmit}
            />
          </Form.Item>
        </ContentaInputGroup>

        <ContentaButtonPrimary
          className="w-100"
          isMentor={isAdvisor}
          loading={loading}
          onClick={onSubmit}
          disabled={loading}
        >
          {t('login.login')}
        </ContentaButtonPrimary>

        <FooterLogin>
          {/* <Link to="/forgot-password">{t('login.forgotPassword')}</Link> */}
          <Link to={infoByRole?.link}>{t('login.createAccount')}</Link>
        </FooterLogin>

        <LoginByGoogle
          onClosePopup={handleClosePopup}
          queryParam={isAdvisor ? 'loginAs=advisor' : 'loginAs=traveller'}
        />
      </Form>
    </ContentaCard>
  );
};

export default LoginForm;
