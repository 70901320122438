import ScheduleDates from 'components/ScheduleDates';
import { ContentaModal } from 'components/Styled/ContentaModal';
import useAdvisorSchedulesAgenda from 'hooks/useAdvisorSchedulesAgenda';
import RescheduleAlertModal from './RescheduleAlertModal';

function ScheduleModal({
  isOpen,
  setOpen,
  availableSchedules,
  advisor,
  setAvailableSchedules,
  isLoading,
  isReschedule = false,
  bookingId = null,
  schedule = null,
  isRescheduleByAdvisor = false,
  confirmRescheduleAskedByTravellerAdvisor = false,
}) {
  const {
    handleClickSchedule,
    handleCloseCancelAlertModal,
    handleSubmitSchedule,
    nextThreeDays,
    previousThreeDays,
    auxClass,
    availableTimesWithDays,
    loadingDates,
    scheduleModalContent,
    schedules,
    showCancelAlertModal,
  } = useAdvisorSchedulesAgenda({
    advisor,
    availableSchedules,
    setAvailableSchedules,
    isOpen,
    isReschedule,
    bookingId,
    currentScheduleToReschedule: schedule,
    setOpen,
    confirmRescheduleAskedByTravellerAdvisor,
  });

  return (
    <>
      <ContentaModal
        closable
        footer={null}
        onCancel={() => setOpen(false)}
        onClose={() => setOpen(false)}
        onOk={() => setOpen(false)}
        open={isOpen && !showCancelAlertModal}
        className="schedule-modal"
      >
        <ScheduleDates
          advisor={advisor}
          auxClass={auxClass}
          availableSchedules={schedules}
          availableTimesWithDays={availableTimesWithDays}
          handleClickSchedule={handleClickSchedule}
          handleSubmitSchedule={handleSubmitSchedule}
          isLoading={isLoading}
          loadingDates={loadingDates}
          nextThreeDays={nextThreeDays}
          previousThreeDays={previousThreeDays}
          schedule={schedule}
          isRescheduleByAdvisor={isRescheduleByAdvisor}
        />
      </ContentaModal>
      <RescheduleAlertModal
        confirmRescheduleAskedByTravellerAdvisor={
          confirmRescheduleAskedByTravellerAdvisor
        }
        handleClose={handleCloseCancelAlertModal}
        isModalOpen={showCancelAlertModal}
        onConfirm={handleSubmitSchedule}
        schedule={schedule}
        scheduleModalContent={scheduleModalContent}
      />
    </>
  );
}

export default ScheduleModal;
