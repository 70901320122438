import RedirectLoader from 'components/RedirectingLoader';
import { GiftRoute } from 'components/Routes/GiftRoute';
import { ProtectedAdvisorRoute } from 'components/Routes/ProtectedAdvisorRoute';
import { useUser } from 'contexts/userContext';
import useGoogleAnalytics from 'hooks/useGoogleAnalytics';
import ServerErrorPage from 'pages/Common/500';
import { Logout } from 'pages/Common/Logout';
import { Login } from 'pages/LoginPages/Login';
import { LoginAdvisor } from 'pages/LoginPages/LoginAdvisor';
import { GuideRegister } from 'pages/MentorPages/Register';
import TravellerRegister from 'pages/TravellerPages/Register';
import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import GiftCardRoutes from 'routes/GiftCard';
import { ROLES } from 'utils/isRole';
import { ProtectedRoute } from './components/Routes/ProtectedRoute';
import { ProtectedRouteAdmin } from './components/Routes/ProtectedRouteAdmin';
import { UnloggedRoute } from './components/Routes/UnloggedRoute';
import { Redirect } from './pages/Redirect';
import AdminRoutes from './routes/Admin';
import MeetRoutes from './routes/Meet';
import MentorRoutes from './routes/Mentor';
import TravellerRoutes from './routes/Traveller';
import UnloggedRoutes from './routes/Unlogged';

export default function AppRoutes() {
  useGoogleAnalytics();
  const { userRole, authUser: user } = useUser();

  const isMentorNav = userRole === ROLES.MENTOR || userRole === ROLES.ADMIN;

  return (
    <Suspense fallback={<RedirectLoader />}>
      <Routes>
        <Route
          path="/login/advisor"
          exact
          element={<LoginAdvisor />}
          key={'login-advisor'}
        />
        <Route
          path="/error"
          exact
          element={<ServerErrorPage />}
          key={'login-advisor'}
        />
        <Route path="/login" exact element={<Login />} key={'login'} />
        <Route path="/logout" exact element={<Logout />} key={'logout'} />
        <Route
          path="/redirecting"
          exact
          element={<Redirect />}
          key={'redirecting'}
        />
        <Route
          path="guide/register"
          exact
          element={<GuideRegister />}
          key={'guide-register'}
        />
        ,
        <Route
          path="register"
          element={<TravellerRegister />}
          key={'register'}
        />
        ,
        <Route
          element={<ProtectedRouteAdmin isMentor={isMentorNav} user={user} />}
          key="admin"
        >
          {AdminRoutes}
        </Route>
        <Route
          element={<ProtectedAdvisorRoute isMentor={isMentorNav} user={user} />}
          key="advisor"
        >
          {MentorRoutes}
        </Route>
        <Route
          element={
            <ProtectedRoute
              isMentor={isMentorNav}
              user={user}
              userRole={userRole}
            />
          }
          key="traveller"
        >
          {TravellerRoutes}
        </Route>
        <Route element={<GiftRoute />} key={'gift-card'}>
          {GiftCardRoutes}
        </Route>
        <Route
          element={
            <UnloggedRoute
              isMentor={isMentorNav}
              user={user}
              key={'unlogged'}
            />
          }
        >
          {UnloggedRoutes}
        </Route>
        <Route
          element={
            <UnloggedRoute
              isMentor={isMentorNav}
              user={user}
              key={'zoom-unlogged'}
            />
          }
        >
          {MeetRoutes}
        </Route>
      </Routes>
    </Suspense>
  );
}
