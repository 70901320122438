/*= ============================================
  Variables
============================================= */

/**
 * TABLE OF CONTENTS
 *
 * COLORS
 * Brand colors...................These are the main brand colors. They will be used the most
 * Neutral colors.................White and black, gray scale, secondary colors.
 * Utility colors.................Other colors to represent states: info, warning, success, etc.
 *
 * TYPOGRAPHY
 * Font families..................The fonts used in the design system.
 * Font weights...................Font weights
 * Sizes..........................Font sizing for body text, paragraphs, inputs
 * Heading sizes..................Font sizing for page headings
 *
 * BREAKPOINTS
 * Breakpoints....................Global responsive breakpoints definitions.
 *
 * SPACING
 * Spacing........................Global fixed set of spacing variables
 */

/*= ============================================
=                  Colors                     =
============================================= */

export const colors = {
  /* Brand Colors */
  primary: {
    main: '#006AC6',
    dark: '#0084D5',
  },
  secondary: {
    main: '#ff9e1b',
    light: '#FFB600;',
  },
  blue: {
    lighter: '#DFFDFC',
    light: '#71EEFB',
    main: '#57BBE6',
    dark: '#4036D3',
    10: '#DFFDFC',
    20: '#71EEFB',
    30: '#57BBE6',
    40: '#4036D3',
    50: '#2B0F9F',
  },
  purple: {
    lighter: '#D5A4F9',
    light: '#A76FF0',
    main: '#3B0688',
    dark: '#180163',
    10: '#D5A4F9',
    20: '#B276F0',
    30: '#3B0688',
    40: '#180163',
  },
  pink: {
    main: '#FF069B',
    light: '#FFB6E0',
    dark: '#FF069B',
    10: '#F4B5EA',
    20: '#E95CC6',
    30: '#D82EA1',
    40: '#8419DC',
  },
  danger: {
    light: '#f97171',
    main: '#FC5050',
    dark: '#F63131',
  },
  error: {
    10: '#FFFDFE',
    20: '#FDDFE4',
    30: '#F78192',
    40: '#D00320',
  },
  success: {
    main: '#00C853',
    dark: '#00B248',
    light: '#00E676',
    30: '#6BBF59',
  },
  green: {
    main: '#71A394',
    light: '#A3D1C2',
    dark: '#4D7C6A',
  },
  yellow: {
    10: '#FBEE8A',
    20: '#F9E550',
    30: '#F09035',
    40: '#9D1F28',
  },

  /* Neutral Colors */
  backgroundColor: '#fff',
  bold: '#231F20',
  light: '#fff',
  dark: '#424242',
  navbarGuide: '#757575',
  text: '#5E5E5E',
  textTitle: '#5E5E5E',
  grayTitle: '#757575',
  disabled: '#E0E0E0',

  neutralBlack: {
    10: '#5E5E5E',
    20: '#868484',
    30: '#4A4647',
    40: '#231F20',
  },

  neutralWhite: {
    10: '#FFF',
    20: '#FCFCFC',
    30: '#F7F7F6',
    40: '#F0F0EF',
  },

  /* Utility colors */
  table: {
    header: '#eaeaea',
  },

  approved: '#02A443',
  disapproved: '#D0164E',
  pending: '#D0775B',
  suspended: '#5E5E5E',

  linearGradient: {
    bluePink: 'linear-gradient(90deg, #4E02D1 0%, #FF069B 100%)',
    redOrange: 'linear-gradient(90deg, #FF0059 0%, #FFB600 100%);',
    blueLightBlue: 'linear-gradient(90deg, #0C02B6 0%, #57BBE6 100%)',
    pinkPurple: 'linear-gradient(90deg, #FF0033 1%, #FC36C4 36%, #BA08FF 100%)',
    blueDarkBlue:
      'linear-gradient(90deg,rgba(0, 106, 198, 0.8) 0%,rgba(0, 106, 198, 0.3) 100%)',
    blackGray: 'linear-gradient(90deg, #241F21 -13.67%, #999999 68.03%)',
  },
};

/*= ====  End of Colors  ====== */

/*= ============================================
=                Typography                   =
============================================= */

/**
 *
 * 1) The playfair font. We'll use this for headings mostly.
 *     https://fonts.google.com/specimen/Inter
 *
 * 2) The raleway font. We'll use this for the body text.
 *     https://fonts.google.com/specimen/Raleway
 */

/* Font families */

const fonts = {
  number: 'Raleway', // 2
  primary: 'Inter', // 1
  texts: 'Raleway', // 2
};
/*= ====  End of Typography  ====== */

/*= ============================================
=            Breakpoints                      =
============================================= */

const breakpoints = {
  xs: '0px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px',
};
/*= ====  End of Breakpoints  ====== */

/*= ============================================
=            Spacing                          =
============================================= */

const spacing = {
  xxs: '4px',
  xs: '8px',
  sm: '16px',
  md: '24px',
  lg: '32px',
  xl: '48px',
  xxl: '64px',
};

/*= ====  End of Spacing  ====== */

export default {
  colors,
  fonts,
  breakpoints,
  spacing,
};
