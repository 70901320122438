import { useAuth0 } from '@auth0/auth0-react';
import {
  CONTENTA_REDIRECT,
  CONTENTA_SELECTED_SCHEDULE,
  CONTENTA_TIMER_CHECKOUT,
} from 'constants/storages-values';
import { add, format, isSameDay } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BookingService from 'services/BookingService';
import ScheduleService from 'services/ScheduleService';
import { errorHandler } from 'utils/errorHandler';
import filterAvailableDates from 'utils/filter-available-dates';
import formatDateCompleteFormat from 'utils/formatDateCompleteFormat';
import getTimeZone from 'utils/getTimeZone';
import orderByDate from 'utils/orderByDate';

import useRescheduleAgenda from './useRescheduleAgenda';
import { useUser } from 'contexts/userContext';

const useAdvisorSchedulesAgenda = ({
  advisor,
  availableSchedules,
  isOpen,
  isReschedule = false,
  bookingId = null,
  currentScheduleToReschedule = null,
  setOpen,
  confirmRescheduleAskedByTravellerAdvisor = false,
}) => {
  const {
    scheduleModalContent,
    showCancelAlertModal,
    checkCanReschedule,
    handleCloseCancelAlertModal,
  } = useRescheduleAgenda({
    currentScheduleToReschedule,
    bookingId,
    onClose: () => setOpen(false),
    confirmRescheduleAskedByTravellerAdvisor,
  });
  const navigate = useNavigate();
  const { authUser: user } = useUser();

  const [unloggedData, setUnloggedData] = useState({});
  const [schedules, setSchedules] = useState([]);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [availableTimesWithDays, setAvailableTimesWithDays] = useState([]);
  const [loadingDates, setLoadingDates] = useState(false);
  const [auxClass, setAuxClass] = useState('disabled');
  const [selectedSchedule, setSelectedSchedule] = useState({});

  function getNextDays(date) {
    const days = [];
    for (let i = 0; i < 3; i += 1) {
      days.push(add(date, { days: i }));
    }
    return days;
  }

  const addIdToSchedule = (schedulelist) => {
    return schedulelist.map((schedule, dayIndex) => {
      const timesWithId = schedule.times.map((time, timeIndex) => ({
        ...time,
        id: `day${dayIndex}-hour${timeIndex}`,
      }));
      return { ...schedule, times: timesWithId };
    });
  };

  async function fetchAvailableSchedules(days) {
    setLoadingDates(true);
    try {
      const timezone = getTimeZone();
      const currentDay = format(days[0], 'yyyy-MM-dd');
      const endDay = format(days[2], 'yyyy-MM-dd');
      const params = `?startsAt=${currentDay}&endsAt=${endDay}&timeZone=${timezone}`;
      const { dates } = await BookingService.getBookingByAdvisorId({
        advisorId: advisor.id,
        params,
      });

      if (!dates) {
        setSchedules([]);
        setLoadingDates(false);
        return;
      }

      const books = filterAvailableDates(dates);
      setSchedules(addIdToSchedule(books));
    } catch (error) {
      setSchedules([]);
    }
    setLoadingDates(false);
  }

  const previousThreeDays = useCallback(() => {
    const firstDay = availableTimesWithDays[0]?.day;

    // TODO - Remove this comment
    // const canChangePage = !isSameDay(firstDay, add(new Date(), { days: 2 }));

    // if (!canChangePage) {
    //   setAuxClass('disabled');
    //   return;
    // }

    const lastDay = availableTimesWithDays.slice(-1)[0]?.day;
    if (isSameDay(lastDay, new Date())) {
      setAuxClass('disabled');
    }

    const previousDays = getNextDays(add(firstDay, { days: -3 }));
    const availableTimes = previousDays.map((time) => ({ day: time }));
    setAvailableTimesWithDays(availableTimes);
    fetchAvailableSchedules(previousDays);
  }, [availableTimesWithDays]);

  const nextThreeDays = useCallback(() => {
    setAuxClass('');
    const lastDate = availableTimesWithDays.slice(-1)[0]?.day;
    const nextDays = getNextDays(add(lastDate, { days: 1 }));
    const availableTimes = nextDays.map((time, index) => ({
      day: nextDays[index],
    }));

    setAvailableTimesWithDays(availableTimes);
    fetchAvailableSchedules(nextDays);
  }, [availableTimesWithDays]);

  const redirectUnloggedUser = (data = unloggedData) => {
    sessionStorage.setItem(
      CONTENTA_SELECTED_SCHEDULE,
      JSON.stringify({
        ...selectedSchedule,
        advisor,
      })
    );

    sessionStorage.setItem(CONTENTA_TIMER_CHECKOUT, new Date().getTime());
    const currentPage = window.location.pathname;
    sessionStorage.setItem(CONTENTA_REDIRECT, currentPage);
    sessionStorage.setItem('retry_schedule', 'true');
    sessionStorage.setItem('url_back', currentPage);
    sessionStorage.setItem('unlogged_data', JSON.stringify(data));

    setUnloggedData({});
    navigate('/login');
  };

  const createSchedule = async ({
    advisor,
    schedule,
    selectedSchedule,
    scheduleData,
    participants,
    isPayingExtraTax,
    isReschedule,
    currentScheduleToReschedule,
    typeSchedule,
  }) => {
    try {
      const { clientSecret, protocol, country } =
        await ScheduleService.createSchedule({
          advisorId: advisor.id,
          schedule: {
            ...schedule,
            participants,
            schedulingType: typeSchedule,
          },
        });

      navigate('/checkout', {
        state: {
          advisor,
          clientSecret,
          country,
          currentScheduleToReschedule,
          isPayingExtraTax,
          isReschedule,
          participants,
          protocol,
          schedule,
          schedulingType: typeSchedule,
          selectedSchedule: { ...selectedSchedule, ...scheduleData },
        },
      });
    } catch (error) {
      handleScheduleError(error);
    }
  };

  const handleScheduleError = useCallback(
    (error) => {
      errorHandler({ error, typeError: 'DEFAULT' });
      if (error?.response?.status === 403) {
        redirectUnloggedUser();
      }
    },
    [redirectUnloggedUser]
  );

  const createScheduleData = (selectedSchedule, presentialSlots) => {
    const presentialTour = selectedSchedule?.presentialTour || {};
    let scheduleData = {
      presentialTour: {
        ...presentialTour,
        selectedSlots: presentialSlots || [],
      },
    };

    if (
      presentialSlots?.length > 0 &&
      presentialTour?.amountHours === undefined
    ) {
      scheduleData.presentialTour.amountHours = presentialSlots.length;
    }

    return scheduleData;
  };

  const getEndDateTime = (date, endAt) => {
    if (endAt === '00:00:00') {
      let nextDay = add(new Date(date), { days: 2 });
      nextDay = format(nextDay, 'yyyy-MM-dd');

      return formatDateCompleteFormat({ date: nextDay, time: '00:00' });
    }

    return formatDateCompleteFormat({ date, time: endAt });
  };

  const createScheduleObject = (selectedSchedule, scheduleData) => {
    const { date, startAt, endAt } = selectedSchedule;
    const schedule = {
      startAt: formatDateCompleteFormat({ date, time: startAt }),
      endAt: getEndDateTime(date, endAt),
      timeZone: getTimeZone(),
    };

    if (scheduleData.presentialTour.amountHours > 0) {
      const endTime = add(new Date(`${date} ${startAt}`), {
        hours: scheduleData.presentialTour.amountHours,
      });
      schedule.endAt = formatDateCompleteFormat({
        date,
        time: format(endTime, 'HH:mm'),
      });
    }

    return schedule;
  };

  const handleSubmitSchedule = useCallback(
    async ({
      isPayingExtraTax = false,
      presentialSlots,
      participants = 1,
      typeSchedule,
    }) => {
      const timerCheckout = new Date().getTime();

      if (!user?.sub) {
        setUnloggedData({
          selectedSchedule,
          presentialSlots,
          participants,
          typeSchedule,
          isPayingExtraTax,
        });
        setShowLoginModal(true);
        return;
      }

      const selectedScheduleStorage = JSON.parse(
        sessionStorage.getItem(CONTENTA_SELECTED_SCHEDULE)
      );

      const scheduleToSend =
        Object.keys(selectedSchedule).length === 0
          ? selectedScheduleStorage
          : selectedSchedule;

      const scheduleData = createScheduleData(scheduleToSend, presentialSlots);

      const schedule = createScheduleObject(scheduleToSend, scheduleData);

      if (isReschedule && !isPayingExtraTax) {
        checkCanReschedule(schedule);
        return;
      }

      const advisorToUse =
        Object.keys(advisor).length === 0 ? scheduleToSend.advisor : advisor;

      createSchedule({
        advisor: advisorToUse,
        schedule,
        selectedSchedule: scheduleToSend,
        scheduleData,
        participants,
        isPayingExtraTax,
        isReschedule,
        currentScheduleToReschedule,
        typeSchedule,
      });

      sessionStorage.setItem(CONTENTA_TIMER_CHECKOUT, timerCheckout);
    },
    [user, selectedSchedule, isReschedule, currentScheduleToReschedule]
  );

  const handleClickSchedule = (schedule, date, presentialTour) => {
    const selectedSchedule = {
      date,
      startAt: schedule?.startTime || schedule.time,
      endAt: schedule?.endTime || schedule.time,
      presentialTour,
    };
    setSelectedSchedule(selectedSchedule);
  };

  useEffect(() => {
    const isAdvisorDetails =
      window.location.pathname.includes('advisor-details');
    if (isOpen || isAdvisorDetails) {
      // current date itll be the current day + 2 days
      const currentDate = add(new Date(), { days: 2 });
      const nextDays = getNextDays(currentDate);
      const availableTimes = orderByDate(availableSchedules)?.map(
        (_, index) => ({
          day: nextDays[index],
        })
      );

      if (!availableTimes) {
        const unavailableTimes = nextDays?.map((time, index) => ({
          day: nextDays[index],
        }));
        setAvailableTimesWithDays(unavailableTimes);
        return;
      }

      setAvailableTimesWithDays(availableTimes);
      const books = filterAvailableDates(availableSchedules);
      setSchedules(addIdToSchedule(books));
    }
  }, [availableSchedules, isOpen]);

  const clearState = () => {
    setAvailableTimesWithDays([]);
    setSelectedSchedule({});
    setSchedules([]);
  };

  useEffect(() => {
    if (!isOpen) {
      clearState();
    }
    return clearState;
  }, [isOpen]);

  useEffect(() => {
    const retrySchedule = sessionStorage.getItem('retry_schedule');
    let timeOut = null;
    if (retrySchedule) {
      const selectedSchedule = JSON.parse(
        sessionStorage.getItem(CONTENTA_SELECTED_SCHEDULE)
      );
      const unloggedData = JSON.parse(sessionStorage.getItem('unlogged_data'));
      sessionStorage.removeItem('unlogged_data');
      sessionStorage.removeItem('url_back');
      sessionStorage.removeItem('retry_schedule');
      timeOut = setTimeout(() => {
        setSelectedSchedule(selectedSchedule);
        handleSubmitSchedule({ ...unloggedData });
      }, 1000);
    }

    return () => clearTimeout(timeOut);
  }, []);

  return {
    auxClass,
    availableTimesWithDays: availableTimesWithDays,
    getNextDays,
    handleClickSchedule,
    handleSubmitSchedule,
    redirectUnloggedUser,
    loadingDates,
    nextThreeDays,
    previousThreeDays,
    selectedSchedule,
    setAvailableTimesWithDays,
    schedules: orderByDate(schedules),
    checkCanReschedule,
    showCancelAlertModal,
    handleCloseCancelAlertModal,
    showLoginModal,
    handleCloseLoginModal: () => setShowLoginModal(false),
    scheduleModalContent,
  };
};

export default useAdvisorSchedulesAgenda;
