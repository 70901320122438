import { useAuth0 } from '@auth0/auth0-react';
import { ContainerApp } from 'components/ContainerApp';
import RedirectLoader from 'components/RedirectingLoader';
import { useUser } from 'contexts/userContext';
import { Navigate, Outlet } from 'react-router-dom';
import checkIsRegistered from 'utils/checkIsRegistered';
import { canViewGuide, isUserGuide } from 'utils/isRole';

export function ProtectedAdvisorRoute({ children, userRole }) {
  const { isAuthenticated, isLoading } = useAuth0();
  const { whoAmI, whoAmILoading } = useUser();

  if (isLoading || whoAmILoading) {
    return <RedirectLoader />;
  }

  const isRegisterRoute = window.location.pathname.includes('guide/register');
  const isLoaded = !isLoading && !isAuthenticated;
  if (isLoaded && isUserGuide(userRole) && !isRegisterRoute) {
    return <Navigate to="/" replace />;
  }

  if (Object.keys(whoAmI).length === 0 && !isRegisterRoute) {
    return;
  }

  const isRegistered = checkIsRegistered({ whoAmI });
  if (!isRegistered && !isRegisterRoute) {
    return <Navigate to="guide/register" replace />;
  }

  if (!canViewGuide(whoAmI)) {
    return <Navigate to="/not-allowed" replace />;
  }

  return <ContainerApp isMentor>{children || <Outlet />}</ContainerApp>;
}
