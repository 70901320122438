import styled from 'styled-components';
import convertPxToRem from 'utils/convert-px-rem';

export const CardContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  gap: 24px;
  margin-top: 24px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-flow: row nowrap;
    margin-top: 0;
  }

  img {
    border-radius: 8px;
    height: 160px;
    object-fit: cover;
    width: 100%;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      width: 160px;
    }
  }

  .advisor-card__content {
    padding: 8px 0;
  }

  .advisor-card__name {
    color: ${({ theme }) => theme.colors.neutralBlack[40]};
    font-family: ${({ theme }) => theme.fonts.texts};
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-size: ${convertPxToRem(18)};
    font-weight: 800;
    letter-spacing: 1px;
    margin: 0;
  }

  .advisor-card__phrase {
    bottom: 2px;
    color: ${({ theme }) => theme.colors.neutralBlack[20]};
    font-family: ${({ theme }) => theme.fonts.texts};
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-size: ${convertPxToRem(12)};
    font-weight: 400;
    position: relative;
  }

  .advisor-card__title {
    color: ${({ theme }) => theme.colors.neutralBlack[20]};
    font-family: ${({ theme }) => theme.fonts.texts};
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-size: ${convertPxToRem(12)};
  }

  .advisor-card__video {
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-left: auto;
    }
    video {
      border-radius: 8px;
      object-fit: cover;
      width: 100%;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        height: 160px;
        width: 200px;
      }
    }
  }
`;
