import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import MentorService from 'services/MentorService';

const MentorContext = createContext(null);

export function MentorProvider({ children }) {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [advisor, setAdvisor] = useState({});

  const getMentorInfo = async () => {
    setIsLoading(true);
    try {
      const response = await MentorService.getMentorByIdPublic(id);
      setAdvisor(response);
    } catch (error) {
      setAdvisor({});
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getMentorInfo();
  }, [id]);

  const value = useMemo(
    () => ({
      mentor: advisor,
      isLoading: isLoading,
    }),
    [advisor, isLoading]
  );

  return (
    <MentorContext.Provider value={value}>{children}</MentorContext.Provider>
  );
}

export const useMentor = () => React.useContext(MentorContext);
