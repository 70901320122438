import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Form, Select, notification } from 'antd';
import { ContentaButtonPrimary } from 'components/Styled/ContentaButton';
import { ContentaInputGroup } from 'components/Styled/ContentaInputGroup';
import { format, isValid } from 'date-fns';
import AvailableSchedules from 'pages/TravellerPages/FindMentor/AvailableSchedules';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import addTimezoneDate from 'utils/addTimezoneDate';

let rescheduleAmountHours = null;
let rescheduleAmountHoursNumber = null;
const ONE_HOUR = 60 * 60 * 1000;
function AvailableSchedulesBook({
  auxClass,
  availableSchedules,
  availableTimesWithDays,
  handleClickSchedule,
  handleSubmitSchedule,
  isInPerson,
  nextThreeDays,
  previousThreeDays,
  slotsPresential,
  isLoading,
  loadingDates,
  schedule = null,
  typeSchedule,
}) {
  if (!availableSchedules) {
    return null;
  }

  const { t } = useTranslation();
  const [selected, setSelected] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [presentialTour, setPresentialTour] = useState({
    amountHours: 0,
    selectedSlots: [],
  });
  const [selectedPresentialSlots, setSelectedPresentialSlots] = useState([]);
  // const [rescheduleAmountHours, setRescheduleAmountHours] = useState(0);

  const handleSelectPresentialSlot = (value) => {
    if (!value) return;

    let selectedSlots = [];
    // value = day0-hour1, index = 0
    const index = value.id.split('-')[0].slice(3);

    const daySlots = availableSchedules[index].times;

    // selectedDayIndex = hour1 = 1
    const foundSelectedDayIndex = Number(value.id.split('-')[1].slice(4));

    // get the next slots based on the amount of hours
    selectedSlots = daySlots.slice(
      foundSelectedDayIndex,
      foundSelectedDayIndex + Number(presentialTour.amountHours)
    );

    // if not have enough slots, throw an error
    if (selectedSlots.length < Number(presentialTour.amountHours)) {
      setIsButtonDisabled(true);
      notification.error({
        message: t('common.error.generic'),
        description: t('findAdvisor.scheduleModal.notEnoughSlots'),
        duration: 4.5,
      });
      return;
    }

    // check if selected slots are sequential
    // ex: schedule 1, start Time: 10:00
    // schedule 2 must be 11:00, schedule 3 must be 12:00, etc
    const isSequential = selectedSlots.every((el, index) => {
      if (index === 0) return true;
      const previousSlot = selectedSlots[index - 1];
      const previousSlotTime = previousSlot.startTime.split(':')[0];
      const currentSlotTime = el.startTime.split(':')[0];
      return Number(currentSlotTime) - Number(previousSlotTime) === 1;
    });
    if (!isSequential) {
      notification.error({
        message: t('common.error.generic'),
        description: t('findAdvisor.scheduleModal.notEnoughSlots'),
        duration: 4.5,
      });
      setIsButtonDisabled(true);
      return;
    }

    // get the last digit of value.id
    const lastDigit = Number(value.id[value.id.length - 1]); // 0
    const prefixId = value.id.slice(0, value.id.length - 1); // day1-hour
    const selectedWithId = selectedSlots.map((el, index) => ({
      ...el,
      id: `${prefixId}${lastDigit + index}`,
    }));

    const presentialSchedules = {
      ...presentialTour,
      selectedSlots: selectedWithId,
    };
    setPresentialTour(presentialSchedules);
    setSelectedPresentialSlots(selectedWithId);

    return presentialSchedules;
  };

  const resetFields = () => {
    setSelected(null);
    setPresentialTour({
      amountHours: schedule?.amountHours || 0,
      selectedSlots: [],
      participants: 1,
    });
    setSelectedPresentialSlots([]);
    setIsButtonDisabled(true);
    if (schedule) {
      setPresentialTour({
        amountHours: rescheduleAmountHoursNumber,
        selectedSlots: [],
        participants: schedule.participants || 1,
      });
    }
  };

  const handleSelectTime = (value) => {
    setSelected(null);
    setSelectedPresentialSlots([]);
    setIsButtonDisabled(true);
    let presentialSchedules;
    if (presentialTour?.amountHours || rescheduleAmountHoursNumber) {
      presentialSchedules = handleSelectPresentialSlot(value);
    }

    setIsButtonDisabled(false);

    setSelected((prevState) => ({
      ...prevState,
      ...value,
      presentialTour: presentialSchedules,
    }));
  };

  const handleClickScheduleButton = () => {
    if (selected?.timeIndex !== undefined) {
      handleClickSchedule(selected);
    }

    handleSubmitSchedule({
      typeSchedule,
      presentialSlots: selectedPresentialSlots,
      participants: presentialTour.participants || 1,
    });
  };

  const handleSelectInPersonTime = (value) => {
    rescheduleAmountHours = value;
    const amountHours = t(`findAdvisor.scheduleModal.${value}`).split(' ')[0];
    setPresentialTour({ amountHours, selectedSlots: [] });
    setSelected(null);
    setSelectedPresentialSlots([]);
    setIsButtonDisabled(true);
  };

  const handleSelectAmountParticipants = (value) => {
    setPresentialTour((prevState) => ({ ...prevState, participants: value }));
    setSelected((prevState) => ({ ...prevState, participants: value }));
  };

  const handleClickPreviousThreeDays = () => {
    previousThreeDays();

    resetFields();
  };

  const handleClickNextThreeDays = () => {
    nextThreeDays();
    resetFields();
  };

  useEffect(() => {
    if (isInPerson && selected?.presentialTour?.selectedSlots?.length > 0) {
      setIsButtonDisabled(false);
      return;
    }
    if (!isInPerson && selected?.time) {
      setIsButtonDisabled(false);
      return;
    }

    setIsButtonDisabled(true);

    return () => {
      setPresentialTour({
        amountHours: 0,
        selectedSlots: [],
      });
      setSelected({});
    };
  }, []);

  useEffect(() => {
    if (schedule) {
      const { startAtUTC, endAtUTC } = schedule;
      const startAt = new Date(addTimezoneDate(startAtUTC)) || new Date();
      const endAt = new Date(addTimezoneDate(endAtUTC)) || new Date();
      const diffInMilliseconds = Math.abs(endAt - startAt);
      const diffInHours = Math.floor(diffInMilliseconds / ONE_HOUR) || 0;
      rescheduleAmountHoursNumber = diffInHours;

      setPresentialTour({ amountHours: diffInHours, selectedSlots: [] });

      switch (diffInHours) {
        case 4:
          rescheduleAmountHours = 'valueFourHours';
          break;
        case 6:
          rescheduleAmountHours = 'valueSixHours';
          break;
        case 8:
          rescheduleAmountHours = 'valueEightHours';
          break;
        case 10:
          rescheduleAmountHours = 'valueTenHours';
          break;
        default:
          rescheduleAmountHours = null;
          break;
      }
    }
  }, [schedule]);

  const checkCanRenderTime = (time) => {
    return isValid(time?.day);
  };

  useEffect(() => {
    if (loadingDates) {
      rescheduleAmountHours = null;
    }
  }, [loadingDates]);

  return (
    <>
      {availableTimesWithDays && (
        <div className="search-header__schedule">
          <button
            onClick={handleClickPreviousThreeDays}
            type="button"
            className={`schedule__btn ${auxClass}`}
          >
            <LeftOutlined />
          </button>

          <div className="schedule__times">
            {availableTimesWithDays?.map(
              (time, index) =>
                checkCanRenderTime(time) && (
                  <div
                    key={`day-${index}`}
                    className="schedule__date-container"
                  >
                    <span>{format(time?.day, 'EEE')}</span>
                    <span>{format(time?.day, 'dd MMM')}</span>
                  </div>
                )
            )}
          </div>

          <button type="button" className="schedule__btn">
            <RightOutlined onClick={handleClickNextThreeDays} />
          </button>
        </div>
      )}

      {isInPerson && (
        <>
          <div className="schedule__amount">
            <span>{t('findAdvisor.scheduleModal.amount')}</span>
            <ContentaInputGroup>
              <Form.Item>
                <Select
                  allowClear
                  defaultActiveFirstOption
                  onChange={handleSelectInPersonTime}
                  value={rescheduleAmountHours}
                  disabled={!!schedule}
                >
                  {Object.entries(slotsPresential)
                    .sort(([, a], [, b]) => a - b)
                    .map(([key]) => (
                      <Select.Option key={key} value={key}>
                        {t(`findAdvisor.scheduleModal.${key}`)}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </ContentaInputGroup>
          </div>

          <div className="schedule__amount">
            <span>{t('findAdvisor.scheduleModal.participants')}</span>
            <ContentaInputGroup>
              <Form.Item>
                <Select
                  allowClear
                  defaultActiveFirstOption
                  onChange={handleSelectAmountParticipants}
                >
                  {[1, 2, 3, 4, 5, 6].map((key) => (
                    <Select.Option key={key} value={key}>
                      {key}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </ContentaInputGroup>
          </div>
        </>
      )}

      <div className="found-mentor__schedules">
        <AvailableSchedules
          availableSchedules={availableSchedules}
          availableTimesWithDays={availableTimesWithDays}
          handleSelectPresentialSlot={handleSelectPresentialSlot}
          isLoading={isLoading}
          loadingDates={loadingDates}
          onClick={handleClickSchedule}
          presentialTour={presentialTour}
          selected={selected}
          setSelected={handleSelectTime}
        />
      </div>

      <ContentaButtonPrimary
        onClick={handleClickScheduleButton}
        disabled={isButtonDisabled}
      >
        {t('buttons.schedule')}
      </ContentaButtonPrimary>
    </>
  );
}

AvailableSchedulesBook.defaultProps = {
  auxClass: '',
  isInPerson: false,
  slotsPresential: [],
};

AvailableSchedulesBook.propTypes = {
  auxClass: PropTypes.string,
  availableSchedules: PropTypes.arrayOf(PropTypes.object).isRequired,
  availableTimesWithDays: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleClickSchedule: PropTypes.func.isRequired,
  handleSubmitSchedule: PropTypes.func.isRequired,
  isInPerson: PropTypes.bool,
  nextThreeDays: PropTypes.func.isRequired,
  previousThreeDays: PropTypes.func.isRequired,
  slotsPresential: PropTypes.arrayOf(PropTypes.object),
};

export default AvailableSchedulesBook;
