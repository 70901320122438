import { useAuth0 } from '@auth0/auth0-react';
import { useSearch } from 'contexts/searchContext';
import { useUser } from 'contexts/userContext';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import MentorService from 'services/MentorService';
import checkIsRegistered from 'utils/checkIsRegistered';
import { errorHandler } from 'utils/errorHandler';
import errorLogger from 'utils/errorLogger';
import { isUserGuide } from 'utils/isRole';

const useHome = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();
  const { userRole, whoAmI, isLoading, userError, logout } = useUser();

  const [data, setData] = useState();
  const { handleRedirectWithFilters } = useSearch();

  useEffect(() => {
    const isRegistered = checkIsRegistered({ whoAmI });
    if (isAuthenticated && isRegistered) {
      navigate(isUserGuide(userRole) ? '/advisor/home' : '/home');
    } else {
      navigate('/');
    }
  }, [isAuthenticated]);

  useEffect(() => {
    async function fetchMentors() {
      try {
        const response = await MentorService.getPublicMentors();
        setData(response);
      } catch (error) {
        errorLogger({ message: '[USE_HOME] error on get advisors', error });
        errorHandler({ error, typeError: 'ADVISORS', t });
      }
    }
    fetchMentors();
  }, []);

  useEffect(() => {
    const errorStatus = userError?.response?.status;
    if (
      userRole === '' &&
      !isLoading &&
      (errorStatus === 403 || errorStatus === 404)
    ) {
      logout();
    }
  }, [userRole, isLoading, userError]);

  return {
    handleRedirectWithFilters,
    data,
  };
};

export default useHome;
