import { useNavigate } from 'react-router-dom';

const useRedirectToAdvisorPage = () => {
  const navigate = useNavigate();

  const redirectToAdvisorPage = (advisor, state = {}) => {
    const { country, name, id } = advisor;
    const countryFormatted = country.replace(/\s/g, '-');
    const nameFormatted = name.replace(/\s/g, '-');

    navigate(`/advisor-details/${countryFormatted}/${nameFormatted}/${id}`, {
      state,
    });
  };

  return { redirectToAdvisorPage };
};

export default useRedirectToAdvisorPage;
