import styled from 'styled-components';

export const CardSchedule = styled.div`
  background: ${(props) => props.theme.colors.light};
  box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06),
    0px 4px 8px -2px rgba(16, 24, 40, 0.1);
  border-radius: 8px;
  position: relative;
  bottom: calc(24px + 40px);

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
      flex-direction: column;
      align-items: center;
    }
  }
`;
